if (typeof Swup !== "undefined") {
    const swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_footer', '#layout_logo', '#layout_cta', '.id--layout-nav-content']
    });

    swup.on('clickLink', function (e) {
        // document.querySelector("[data-lib-ssm]").classList.remove('ssm-nav-visible');
        // document.querySelector(".ssm-nav").classList.remove('ssm-nav-visible');
        // clearTimeout(ssm_timeout);
        //
        // document.documentElement.classList.remove("ssm-active");
        //
        // ssm_timeout = setTimeout(function(){
        //     document.documentElement.classList.remove("ssm-nav-visible", "ssm-opened");
        // },1250);
        // document.querySelector(".ssm-overlay").style["display"] = "none";

        document.body.classList.remove("overflow--hidden");

        if (document.querySelector(".drawer-active") !== null) {
            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").hide()
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.close();
        }

        if (window.location.href === e.target.closest('a').href) {
            LibLenis.scrollTo(0, {lock: true, force: true});
        }
    });

    swup.on('animationOutDone', () => {
        ScrollTrigger.killAll()

        LibLenis.scrollTo(0, {immediate: true, lock: true, force: true});
    })

    swup.on('contentReplaced', function () {
        ((layouts) => {
            document.documentElement.classList.remove("digital", "boost", "creative");
            layouts.map((layout) => document.querySelector("#layout_main").classList.contains(layout) && document.documentElement.classList.add(layout))
        })(["digital", "creative", "boost"]);

        let anchor = [...document.querySelectorAll("[data-lib-anchor-id]")];

        if (anchor[0] !== null) {
            anchor.forEach((element) => {
                if (window.location.hash && element.getAttribute("data-lib-anchor-id") === window.location.hash.replace('#', '')) {
                    cssLoaded(function () {
                        LibLenis.scrollTo(element,{immediate: true, lock: true, force: true});
                    }, 50);
                }
            });
        }

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        window.setScrollDetectBackground()
        LibAnchor.init();

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function(config){
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (window.rc && window.rc.retargetingHit) {
            window.rc.retargetingHit(retargetingConf);
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init();
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}
