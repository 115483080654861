application.register("part_ui_input", class extends Stimulus.Controller {
    connect() {
        let self = this, element = self.element;

        self.validateInput(element, false);

        if (element.querySelector("textarea") !== null) {
            importScript(cdnjs.autosize, function(){
                autosize(element.querySelector("textarea"));
            });
        }

        element.addEventListener("change", () => {
            self.validateInput(element, true);
        });
    }

    validateInput(element, validate) {
        let input = element.querySelectorAll("input, textarea")[0];

        if (input.outerHTML.match(/(data-no-validate|readonly)/) === null && validate) {
            element.classList.remove("state--invalid", "state--valid", "state--active");

            if (input.checkValidity()) {
                element.classList.add("state--valid");
            } else {
                element.classList.add("state--invalid");
            }
        }

        if (input.value !== "") {
            element.classList.add("state--active");
        }
    }
});