loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }

    copy(e) {
        e.preventDefault();

        let textArea = document.createElement('textArea');
        textArea.value = e.currentTarget.dataset['actionValue'];
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy")
        document.body.removeChild(textArea);
    }

    downloadBrand(e) {
        e.preventDefault();
        const file = e.currentTarget.dataset.actionFile
        const content = `<div class="part_dialog part_dialog_download align--center" data-controller="part_dialog_download_brand">
                            <div class="wrp_dialog">
                                <form class="wrp_dialog_body text--color-invert" data-action="submit->part_dialog_download_brand#handlePassword" action="/brandDownload.php" method="post" autocomplete="off">
                                    <div class="wrp_form_body row row--h-8">
                                        <div class="col flex--grow">
                                            <div class="part_ui_input" data-controller="part_ui_input">
                                                <input type="password" name="password" autocomplete="off">
                                                <label>Zadejte heslo</label>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <button type="submit"><span class="part_ui_icon text--size-40 icon--arrow-right"></span></button>
                                        </div>
                                    </div>
                                    <div class="wrp_form_warning part_ui_notice text--color-error display--none"></div>
                                </form>
                                <form hidden action="/brandDownload.php" method="post" data-target="part_dialog_download_brand.form">
                                    <input type="hidden" name="file" value="${file}">                                
                                </form>
                            </div>
                        </div>`

        LibDialog.show(content, "download")
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        let element = this.element;

        if (typeof element.dataset.libDialogOpen !== "undefined") {
            let url = element.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")) );
            } else {
                LibDialog.show(document.querySelector(element.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }
    }

    show(e) {
        let element = e.currentTarget;
        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();
    }
});

application.register("lib-map", class extends Stimulus.Controller {
    connect() {
        LibMap(this.element);
    }
});

application.register("lib-ns", class extends Stimulus.Controller {
    connect() {
        if (!('scrollBehavior' in document.documentElement.style)) {
            importScript(cdnjs.seamless, () => seamless.polyfill());
        }

        bodyLoaded(() => LibNativeSlider(this.element, this.element.closest('[class^="comp_"]')))
    }
});

application.register("lib-over-image", class extends Stimulus.Controller {
    connect() {
        if (document.documentElement.classList.contains("no-touch")) {
            LibOverImage(this.element);
        }
    }
});

application.register("lib-dropdown", class extends Stimulus.Controller {
    connect() {
        lui_dropdown(this.element);
    }
});

application.register("lib-spirit", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.spirit, () => {
            spirit.loadAnimation({
                container: this.element,
                autoPlay: false,
                path: '/assets/svg/appmotion.json',
            }).then(timeline => new IntersectionObserver(([{isIntersecting}]) => isIntersecting && timeline.play()).observe(this.element));
        });
    }
});

application.register("lib-parallax", class extends Stimulus.Controller {
    connect() {
        if (!document.documentElement.classList.contains("mobile")) {
            importScript(cdnjs.parallax, () => {
                new Parallax(this.element, {
                    hoverOnly: true,
                    relativeInput: true,
                    scalarY: 5,
                    scalarX: 5
                });
            });
        }
    }
});

application.register("lib-canvasvideo", class extends Stimulus.Controller {
    connect() {
        canvasVideo(this.element)
    }
})
