const LibNativeSlider = function(selector, parent) {
    let isDown = false, startX, scrollLeft;
    let progress = parent.querySelector("[data-lib-ns-progress]");
    let prev = parent.querySelector("[data-lib-ns-prev]");
    let next = parent.querySelector("[data-lib-ns-next]");
    let items = [...selector.children];
    let scrollPadding = parseInt(getComputedStyle(selector).scrollPaddingLeft);
    let activeSlide = items[0];
    let activeWidth = activeSlide.clientWidth;
    let edgeOffset = 24;
    let maxScrollLeft = selector.scrollWidth - selector.clientWidth;

    activeSlide.classList.add("state--active")

    if (selector.scrollLeft === 0) {
        parent.classList.add('lib-ns-start')
    } else if ((selector.scrollLeft + selector.clientWidth) / selector.scrollWidth === 1) {
        parent.classList.add('lib-ns-end')
    }

    let grabbing = () => {
        isDown = false;
        selector.classList.remove('is--grabbing');
        selector.scrollLeft = selector.scrollLeft - 1;
    };

    selector.addEventListener('mouseleave', grabbing);

    selector.addEventListener('mouseup', grabbing);

    selector.addEventListener('mousedown', (e) => {
        isDown = true;
        startX = e.pageX - selector.offsetLeft;
        scrollLeft = selector.scrollLeft;
    });

    selector.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();

        const x = e.pageX - selector.offsetLeft;
        const walk = (x - startX) * 1.25;
        selector.classList.add('is--grabbing');
        selector.scrollLeft = scrollLeft - walk;

        selector.ondragstart = (dragEvent) => {
            dragEvent.preventDefault();
        };
    });

    selector.addEventListener("scroll", () => {
        if (progress !== null) {
            progress.value = ((selector.scrollLeft + selector.clientWidth) / selector.scrollWidth * 100).toFixed(2);
        }

        parent.classList.remove('lib-ns-start', 'lib-ns-end')

        if (selector.scrollLeft === 0) {
            parent.classList.add('lib-ns-start')
        } else if ((selector.scrollLeft + selector.clientWidth) / selector.scrollWidth === 1) {
            parent.classList.add('lib-ns-end')
        }

        activeSlide.classList.remove("state--active");

        for(let i=0; i<items.length; i++) {
            if(items[i].offsetLeft - scrollPadding >= selector.scrollLeft) {
                activeSlide = items[i];
                break;
            }
        }

        activeSlide.classList.add("state--active");

        if (prev !== null && next !== null) {
            if (selector.scrollLeft >= maxScrollLeft - edgeOffset) {
                next.setAttribute('disabled', 'disabled')
            } else {
                next.removeAttribute('disabled', 'disabled')
            }

            if (selector.scrollLeft <= edgeOffset) {
                prev.setAttribute('disabled', 'disabled')
            } else {
                prev.removeAttribute('disabled', 'disabled')
            }
        }
    }, { passive: true });

    if (progress !== null) {
        progress.addEventListener("click", (e) => {
            selector.scrollLeft = (selector.scrollWidth / 100) * (e.clientX - e.target["offsetLeft"]) / e.target["clientWidth"] * 100;
        });
    }

    if (prev !== null && next !== null) {
        prev.addEventListener("click", () => {
            if(activeSlide.previousElementSibling !== null) {
                activeWidth = activeSlide.previousElementSibling.clientWidth;
            }
            selector.scroll({ left: selector.scrollLeft - activeWidth, behavior: "smooth" });
        });

        next.addEventListener("click", () => {
            activeWidth = activeSlide.clientWidth;
            selector.scroll({ left: selector.scrollLeft + activeWidth, behavior: "smooth" });
        });
    }
};
