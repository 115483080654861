bodyLoaded(function () {
    window.LibLenis = new Lenis({
        wheelMultiplier: document.documentElement.classList.contains('firefox') ? 1.2 : 1
    })

    function raf(time) {
        window.LibLenis.raf(time)
        requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    window.setScrollDetectBackground()
    window.addEventListener('scroll',scrollDetectBackground)
})
