const LibOverImage = function(selector) {
    let timeout, overImage = selector.querySelector(`#${selector.dataset["libOverImage"]}`);

    selector.addEventListener("mousemove", function(e) {
        if (typeof overImage !== "undefined") {
            overImage.style.left = e.x + "px";
            overImage.style.top = e.y + window.scrollY + "px";
        }
    }, false);

    selector.addEventListener("mouseenter", (e) => {
        if (e.target.dataset["libOverImageId"]) {
            let item = document.getElementById(e.target.dataset["libOverImageId"]);

            clearTimeout(timeout);

            if (item) {
                for (let element of overImage.querySelectorAll("[data-lib-over-image-item]")) {
                    element.classList.remove('state--active')
                }

                item.classList.add("state--active");
            }
        }
    }, true);

    selector.addEventListener("mouseleave", (e) => {
        if (e.target.dataset["libOverImageId"]) {
            timeout = setTimeout(function () {
                for (let element of overImage.querySelectorAll("[data-lib-over-image-item]")) {
                    element.classList.remove('state--active')
                }
            }, 100);
        }
    }, true);
};
