application.register("part_ui_btn", class extends Stimulus.Controller {
    connect() {
        if(this.element.classList.contains("is--magnet")) {
            this.element._magnet = this

            this.createMagnet()

            this._observer = new ResizeObserver(() => {
                if(this.element.classList.contains("is--magnet")) {
                    this.calculateSizePosition()
                }
            })

            this._observer.observe(document.querySelector('#layout_scroll'))
        }
    }

    disconnect() {
        if(typeof  this._observer !== 'undefined') {
            this._observer.disconnect()
        }
    }

    createMagnet() {
        this._dom = {}
        this._top = 0

        const stickyParent = this.element.closest('[data-scroll-sticky]')

        this._dom.el = this.element
        this._dom.text = this.element.querySelector('span')
        this._renderedStyles = {
            tx: { previous: 0, current: 0, amt: 0.1 },
            ty: { previous: 0, current: 0, amt: 0.1 }
        }
        this._state = {
            hover: false
        }

        this.calculateSizePosition()
        requestAnimationFrame(() => this.render())
    }

    calculateSizePosition() {
        this._rect = this._dom.el.getBoundingClientRect()
        this._distanceToTrigger = this._rect.width * 0.75
        this._top = this._rect.top + window.scrollY
    }

    render() {
        if (window.innerWidth > 960) {
            const distanceMouseButton = luiDistance(lui_mousepos.x + window.scrollX, lui_mousepos.y + window.scrollY, this._rect.left + this._rect.width / 2, this._top + this._rect.height / 2)
            let x = 0
            let y = 0

            if (distanceMouseButton < this._distanceToTrigger) {
                if (!this._state.hover) {
                    this.enter()
                }
                x = (lui_mousepos.x + window.scrollX - (this._rect.left + this._rect.width / 2)) * 0.3
                y = (lui_mousepos.y + window.scrollY - (this._top + this._rect.height / 2)) * 0.3
            } else if (this._state.hover) {
                this.leave()
            }

            this._renderedStyles.tx.current = x
            this._renderedStyles.ty.current = y

            for (const key in this._renderedStyles) {
                this._renderedStyles[key].previous = luiLerp(this._renderedStyles[key].previous, this._renderedStyles[key].current, this._renderedStyles[key].amt)
            }

            this._dom.el.style.transform = `translate3d(${this._renderedStyles.tx.previous * 0.6}px, ${this._renderedStyles.ty.previous * 0.6}px, 0)`

            if(this._dom.text !== null) {
                this._dom.text.style.transform = `translate3d(${this._renderedStyles.tx.previous * 0.1}px, ${this._renderedStyles.ty.previous * 0.1}px, 0)`
            }
        }

        requestAnimationFrame(() => this.render())
    }

    enter() {
        this._state.hover = true
        this._dom.el._addDataValue('state', 'hover')
    }

    leave() {
        this._state.hover = false
        this._dom.el._removeDataValue('state', 'hover')
    }
})
