application.register("part_item_card_expand", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.slideElement)
    }

    toggle(e) {
        e.preventDefault();
        e.currentTarget.blur()

        if (!this.element._hasDataValue('state', 'active')) {
            this.open(this.element, this.targets.find('body'))
        } else {
            this.close(this.element, this.targets.find('body'))
        }
    }

    open(element, body) {
        element._addDataValue('state', 'opening')

        body.classList.remove('animate-fadeout')
        body.classList.add('animate-fadein')
        element.setAttribute('aria-expanded', true)

        SlideElement.down(body, {}).then(() => {
            element._removeDataValue('state', 'opening')
            element._addDataValue('state', 'active')
        })
    }

    close(element, body) {
        element._addDataValue('state', 'closing')

        body.classList.remove('animate-fadein')
        body.classList.add('animate-fadeout')

        SlideElement.up(body, {}).then(() => {
            element._removeDataValue('state', 'closing')
            element._removeDataValue('state', 'active')
            element.setAttribute('aria-expanded', false)
        })
    }
});
