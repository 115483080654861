function scrollDetectBackground() {
    if(window._scrollDectectBacgrkoundComps[0] === null) return

    window._scrollDectectBacgrkoundComps.forEach((elm, index) => {
        if (elm.classList.contains('only--m')) {
            return
        }

        let top = elm.getBoundingClientRect().top + document.body.scrollTop;
        let bottom = -elm.clientHeight;

        window._scrollDectectBacgrkoundSections[index] = top <= 0 && top >= bottom;

        if (!window._scrollDectectBacgrkoundSections.includes(true)) {
            if (!document.documentElement.classList.contains("is--header-invert")) {
                document.documentElement.classList.add("ui-dark");
            } else {
                document.documentElement.classList.remove("ui-light");
                document.documentElement.classList.remove("ui-dark");
            }
        } else {
            if (!document.documentElement.classList.contains("is--header-invert")) {
                document.documentElement.classList.remove("ui-dark");
                document.documentElement.classList.remove("ui-light");
            } else {
                document.documentElement.classList.add("ui-light");
            }
        }
    });
}

function setScrollDetectBackground() {
    window._scrollDectectBacgrkoundComps = document.querySelectorAll(`[class^="comp_"].background--color-main`);
    window._scrollDectectBacgrkoundSections = [];
    scrollDetectBackground()
}
