application.register("part_dialog_demo", class extends Stimulus.Controller {
  toggleSwitch({ currentTarget }) {
    let id = currentTarget.closest('[data-switch]').dataset.switch

    if (!currentTarget.closest('[data-switch]').classList.contains('state--active')) {
      currentTarget.closest('[data-switch]').classList.add('state--active')
      this.element.querySelector(`[data-image="${id}"]`).classList.add('state--active')
    } else {
      currentTarget.closest('[data-switch]').classList.remove('state--active')
      this.element.querySelector(`[data-image="${id}"]`).classList.remove('state--active')
    }
  }
});
