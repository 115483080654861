function importScript(url, callback, options) {
    if (typeof url === "string") {
        url = [url];
    }

    if (typeof options === "undefined") {
        options = {};
    }

    if (typeof options.async === "undefined") {
        options.async = false;
    }

    if (typeof options.delay === "undefined" || window.swupActive) {
        options.delay = 0;
    }

    Promise.all(url.map((url) => {
        if (importScript.used.indexOf(url) === -1) {
            importScript.used.push(url);

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const script = document.createElement('script');
                    script.onload = resolve;
                    script.onerror = reject;
                    script.async = options.async;
                    script.src = url;
                    document.head.appendChild(script);
                }, options.delay);
            });
        } else {
            return new Promise((resolve) => {
                let interval = setInterval(function () {
                    if (importScript.loaded.includes(url)) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 1);
            });
        }
    })).then(texts => {
        texts.forEach((data, index) => {
            if (typeof data !== "undefined") {
                importScript.loaded.push(url[index]);
            }
        });
    }).then(() => {
        if (callback) {
            callback();
        }
    });
}
