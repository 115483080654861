application.register("comp_references_single_dafit", class extends Stimulus.Controller {
    get target() {
        return {
            scene: this.targets.find("scene"),
            banner: this.targets.find("banner"),
            device: this.targets.find("device"),
            sparks: this.targets.find("sparks"),
        }
    }

    connect() {
        let self = this;

        gsap.registerPlugin(ScrollTrigger);

        // //complex animation stuff, see https://codepen.io/GreenSock/pen/1dc38ca14811bc76e25c4b8c686b653d
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: self.target.scene,
                scrub: true,
                pin: true,
                start: "top top",
                end: "+=150%"
            }
        });

        tl
            .fromTo(self.target.banner,
                {
                    scale: 1.15,
                    ease: "none",
                },
                {
                    scale: 0.35,
                    ease: "none",
                })
            .from(self.target.device,
                {
                    scale: 3.5,
                    ease: "none"
                }, 0)
            .from(self.target.sparks,
                {
                    scale: 3.5,
                    ease: " slow(0.5, 1, false)",
                },0)
            .from(self.target.sparks,
                {
                    opacity: 0,
                    ease: "none",
                },0);
    }
});
