const LibCookieConsent = {
    setCookie: (cname, cvalue) => {
        const hostnameArray = window.location.hostname.split('.')
        const numberOfSubdomains = hostnameArray.length - 2
        const domain = hostnameArray.length === 2 ? window.location.hostname : hostnameArray.slice(numberOfSubdomains).join('.')

        const d = new Date();
        d.setTime(d.getTime() + (31556926 * 1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + domain;
    },
    getCookie: (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    },
    init: () => {
        const type = LibCookieConsent.getCookie('lib-cookieconsent')

        if (type !== null) {
            JSON.parse(type).forEach(type => LibCookieConsent.append(type))
        }
    },
    set: (type) => {
        LibCookieConsent.setCookie('lib-cookieconsent', JSON.stringify(type))

        if (type.length > 0) {
            type.forEach(type => LibCookieConsent.append(type))
        } else {
            LibCookieConsent.setCookie('lib-cookieconsent', JSON.stringify([]))
            LibCookieConsent.remove()
        }
    },
    remove: () => {
        document.cookie.split(';').forEach(c => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
        })
    },
    append: (type) => {
        document.querySelectorAll('[data-lib-cookieconsent]').forEach(elm => {
            if (type === 'all' || elm.getAttribute('data-lib-cookieconsent') === type) {
                const script = document.createElement('script')
                let delay = 0

                ;[...elm.attributes].forEach((attribute) => {
                    if (attribute.specified) {
                        if (attribute.name.indexOf('data-lib-cookieconsent') === -1 && attribute.name.indexOf('type') === -1) {
                            script.setAttribute(attribute.name, attribute.value)
                        }
                    }
                })

                script.innerHTML = elm.innerHTML

                if (elm.getAttribute('data-lib-cookieconsent-delay')) {
                    delay = parseInt(elm.getAttribute('data-lib-cookieconsent-delay'))
                }

                setTimeout(() => {
                    if (elm.closest('body') !== null) {
                        document.body.appendChild(script)
                    } else if (elm.closest('head') !== null) {
                        document.head.appendChild(script)
                    }

                    elm.remove()
                }, delay)
            }
        })
    }
}
