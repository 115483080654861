application.register("part_ui_video", class extends Stimulus.Controller {
    connect() {
        let elm = this.element;
        let video = elm.querySelector("video");

        video.addEventListener("ended", () => {
            elm.classList.remove("state--playing");
            video.currentTime = 0;
            video.removeAttribute("controls");
        });
        video.addEventListener("pause", () => {
            video.removeAttribute("controls");
            elm.classList.remove("state--playing");
        });
        video.addEventListener("play", () => {
            video.setAttribute("controls", true);
            elm.classList.add("state--playing");
        });

        elm.addEventListener("click", function (e) {
            e.preventDefault();

            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
        })
    }
});