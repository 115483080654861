application.register('lib-gsap-parallax', class extends Stimulus.Controller {
    connect() {
        gsap.registerPlugin(ScrollTrigger)

        const trigger = this.element;
        const speed = trigger.dataset.speed !== undefined ? trigger.dataset.speed * -1 : 1;
        const start = trigger.dataset.start !== undefined ? trigger.dataset.start : `top bottom`;
        const direction = trigger.dataset.direction !== undefined ? trigger.dataset.direction : 'vertical';
        const y = document.documentElement.clientWidth * speed * 0.1
        const x = document.documentElement.clientHeight * speed * 0.2

        const setY = gsap.quickSetter(trigger, 'y', 'px')
        const setX = gsap.quickSetter(trigger, 'x', 'px')
        const set3D = gsap.quickSetter(trigger, 'force3D')

        cssLoaded(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger,
                    scrub: true,
                    start: isNaN(start) ? start : parseInt(start),
                    end: 'bottom top',
                    onUpdate: ({ progress }) => {
                        progress = progress - 0.5
                        direction === 'vertical' ? setY(progress * y) : setX(progress * x)
                        set3D(progress > 0 && progress < 1)
                    },
                    markers: false
                }
            })
        })
    }
})
