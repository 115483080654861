let dynamicControllers = ["part_ui_input", "part_ui_file", "part_ui_video", "part_ui_select", "part_ui_wsw", "part_cookieconsent", "part_form_cookieconsent", "part_ui_btn"];
let dynamicActions = [[`a[href^="#"]`, "click->lib#anchor"], [`.part_ui_btn, .part_ui_link`, "click->lib#ripple"]];
let lang = document.documentElement.getAttribute("lang");
let ssm_timeout = 0;

window.lui = {
    refs: {
        header: "#layout_header"
    },
    data: {}
};

const application = Stimulus.Application.start(document.documentElement, {
    controllerAttribute: "data-controller",
    actionAttribute: "data-action",
    targetAttribute: "data-target"
});

importScript.used = [];
importScript.loaded = [];

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function () {
        if (!document.documentElement.classList.contains("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function (e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

let lastScrollTop = 0;

window.addEventListener('scroll', function (e) {
    let position = document.documentElement.scrollTop;

    if (position > 5) {
        document.documentElement.classList.add("is--header-not-top");
    } else {
        document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
    }

    if (position > lastScrollTop) {
        document.documentElement.classList.remove("is--header-pinned");
        document.documentElement.classList.add("is--header-unpinned");
    } else {
        document.documentElement.classList.remove("is--header-unpinned");
        document.documentElement.classList.add("is--header-pinned");
    }

    lastScrollTop = position <= 0 ? 0 : position;
});

(function preloadPage() {
    if (document.body.classList.contains("body--preload")) {
        cssLoaded(function(){
            if (document.getElementById("layout_intro") === null) {
                document.body.classList.remove("body--preload");
                setTimeout(function () {
                    document.body.classList.add("body--loaded");
                }, 300);
            } else {
                setTimeout(function () {
                    document.body.classList.remove("body--preload");
                }, 500);

                setTimeout(function () {
                    document.body.classList.add("body--loaded");
                }, 800);
                document.getElementById("layout_intro").addEventListener('transitionend', (e) => {
                    if (e.target["id"] === "layout_intro") {
                        if (typeof e.pseudoElement !== "undefined" && e.pseudoElement.length === 0 || typeof e.pseudoElement === "undefined") {
                            document.getElementById("layout_intro").classList.add("is--hidden");
                            document.head.querySelector("[data-preload-styles]").remove();
                        }
                    }
                });
            }
        });
    }
})();

(function preloadFont() {
    if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
        if (typeof document.fonts !== "undefined") {
            document.fonts.ready.then(function () {
                document.documentElement.classList.add("wf-active");
            });

            setTimeout(() => {
                if (!document.documentElement.classList.contains("wf-active")) {
                    document.documentElement.classList.add("wf-active");
                }
            }, 500);
        } else {
            document.documentElement.classList.add("wf-active");
        }
    }
})();

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

window.addEventListener('resize', () => {
    document.querySelectorAll('.ui-btn.type--cta').forEach(button => {
        application.getControllerForElementAndIdentifier(button, 'part_ui_btn').calculateSizePosition()
    })
})

if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !document.documentElement.classList.contains("ie")) {
    importScript(cdnjs.picturefill);
}
