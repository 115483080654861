application.register("comp_visual_marquee", class extends Stimulus.Controller {
    connect() {
        this.element.querySelectorAll(`[data-ref="marquee"]`).forEach((elm) => {
            elm.insertAdjacentHTML('beforeend', elm.innerHTML);
        });

        this.element.querySelector(`[data-click="scrollNext"]`).addEventListener("click", () => {
            LibLenis.scrollTo(this.element.nextElementSibling);
        });
    }
});
