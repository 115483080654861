application.register("comp_references_single_oxygen_creative", class extends Stimulus.Controller {
    get target() {
        return {
            intro: this.targets.find("intro")
        }
    }

    connect() {
        let self = this;

        gsap.registerPlugin(ScrollTrigger);

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: self.target.intro,
                scrub: true,
                pin: true,
                start: "top top",
                end: "+=400%"
            }
        });

        tl
            .from(self.target.intro.querySelector('[data-ref="top"]'),
                {
                    scale: 3.35,
                    ease: "none"
                }, 0)
            .from(self.target.intro.querySelector('[data-ref="bot"]'),
                {
                    scale: 3.125,
                    ease: "none"
                }, 0)
            .to({}, {duration: 0.25})
    }
});
