application.register("comp_references_single_siemens", class extends Stimulus.Controller {
    get target() {
        return {
            pressLightDark: this.targets.find("pressLightDark"),
            anniversaryVisual:  this.targets.find("anniversaryVisual")
        }
    }

    connect() {
        let self = this;

        gsap.registerPlugin(ScrollTrigger);

        let tlPressLightDark = gsap.timeline({
            scrollTrigger: {
                trigger: self.target.pressLightDark,
                scrub: true,
                pin: false,
                start: "top-100 center",
                end: "top+200"
            }
        });

        tlPressLightDark.fromTo(self.target.pressLightDark.querySelector('[data-ref="pressDark"]'),
            {
                opacity: 0,
                ease: "ease",
            },
            {
                opacity: 1,
                ease: "ease",
            });

        let tlAnniversaryVisual = gsap.timeline({
            scrollTrigger: {
                trigger: self.target.anniversaryVisual,
                scrub: true,
                pin: false,
                start: "top bottom-=200px",
                end: "bottom bottom"
            }
        });

        tlAnniversaryVisual
            .from(self.target.anniversaryVisual.querySelector('[data-ref="visualFamily"]'),
                {
                    scale: 2,
                    ease: "linear"
                })
            .from(self.target.anniversaryVisual.querySelector('[data-ref="visualBoy"]'),
                {
                    scale: 2,
                    ease: "linear",
                })
    }
});
