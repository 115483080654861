application.register("comp_gallery_photobook", class extends Stimulus.Controller {
    connect() {
        cssLoaded(() => {
            let maxWidth = window.innerWidth;
            let ratio = 1;

            [...this.element.querySelectorAll("[data-width]")].map((item)=>{
                let width = item.dataset["width"];

                if(width >= maxWidth) {
                    if((width/maxWidth) > ratio) {
                        ratio = width/maxWidth;
                    }
                }
            });

            if(window.innerWidth < 961) {
                ratio*=1.2;
            }

            [...this.element.querySelectorAll("[data-width]")].map((item)=>{
                item.style.width = (item.dataset["width"]/ratio)/maxWidth*100 + "%";
            });

            this.element.scrollLeft = 0;
        });
    }
});