application.register("comp_references_single_hotelcube_creative", class extends Stimulus.Controller {
    get target() {
        return {
            scene: this.targets.find("scene"),
            image: this.targets.find("image"),
            logo: this.targets.find("logo"),
            device: this.targets.find("device"),
        }
    }

    connect() {
        let self = this;

        gsap.registerPlugin(ScrollTrigger);
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: self.target.scene,
                scrub: true,
                pin: true,
                start: "top top",
                end: "+=150%"
            }
        });

        tl
            .fromTo(self.target.image,
                {
                    scale: 1.2,
                    ease: "none",
                },
                {
                    scale: 0.675,
                    ease: "none",
                })
            .from(self.target.device,
                {
                    scale: 1.85,
                    ease: "none"
                }, 0)
            .from(self.target.logo,
                {
                    y: 200,
                    ease: "none"
                }, 0)
            .from(self.target.logo,
                {
                    scale: 3,
                    ease: "none"
                },0)
            .from(self.target.logo,
                {
                    opacity: 0,
                    ease: "none"
                },0);
    }
});
