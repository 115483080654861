const cdnjs = {
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "parallax": "https://cdn.jsdelivr.net/npm/parallax-js@3.1.0/dist/parallax.min.js",
    "spirit": "https://cdn.jsdelivr.net/npm/spiritjs@3.0.3/dist/spirit.min.js",
    "jquery": "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.min.js",
    "seamless": "https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@1.0.9/dist/es5/seamless.min.js",
    "gsap": "https://cdn.jsdelivr.net/npm/gsap@3.10.4/dist/gsap.min.js",
    "gsapScrollTrigger": "https://cdn.jsdelivr.net/npm/gsap@3.10.4/dist/ScrollTrigger.min.js",
    "slideElement": "https://cdn.jsdelivr.net/npm/slide-element@2.3.0/dist/index.umd.min.js",
    "lottie": "https://cdn.jsdelivr.net/npm/lottie-web@5.9.6/build/player/lottie.min.js"
};
