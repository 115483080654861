application.register("comp_references_single_hotel", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.slideElement)
    }

    showReferences({currentTarget}) {
        arguments[0].preventDefault()

        const buttonWrapper = currentTarget.closest('.elm_comp_more')
        const references = currentTarget.closest('.ref--hotel-references').querySelector('.wrp_comp_body')
        const referencesCols = [...references.querySelectorAll('.col')]

        SlideElement.up(buttonWrapper)
        referencesCols.forEach(col => {
            SlideElement.down(col)
        })

        setTimeout(() => {
            references.scroll({left: 0, behavior: 'instant'})
        },50)

        setTimeout(() => {
            references.classList.add('state--active')
            ScrollTrigger.refresh()
        },750)
    }
})
