application.register("lib-ajax", class extends Stimulus.Controller {
    link(e) {
        e.preventDefault();

        let element = e.currentTarget;

        importScript(cdnjs.jquery, () => {
            $.ajax({
                url: element.getAttribute("href"),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fetchHandler(payload);
            });
        })
    }

    linkComponent(e) {
        e.preventDefault();

        let url = e.currentTarget.getAttribute('data-href');

        if (!url || !url.length) {
            url = e.currentTarget.getAttribute('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        importScript(cdnjs.jquery, () => {
            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fetchHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        LibDialog.show(payload.dialog);
                    }
                });
            });
        })
    }

    form (e) {
        e.preventDefault();

        window.plausible('FormSubmitStart', {
            props: {
                formId: e.currentTarget.id
            }
        })

        let frm = e.currentTarget,
            formData = new FormData(frm);

        frm.querySelector('button[type=submit]').classList.add('state--loading');

        importScript(cdnjs.jquery, () => {
            $.ajax({
                method: frm.getAttribute('method'),
                url: frm.getAttribute('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fetchHandler(payload, function () {
                    if (typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        LibDialog.show(payload.dialog);
                    }
                });
                frm.querySelector('button[type=submit]').disabled = 'disabled'
            }).always(function () {
                frm.querySelector('button[type=submit]').classList.remove('state--loading');
                window.plausible('FormSubmit', {
                    props: {
                        formId: frm.id
                    }
                })
            });
        })
    }
});
