function LibMap(selector) {
    let data = JSON.parse(selector.getAttribute("data-lib-map"));
    importScript(cdnjs.googlemap.replace("APIKEY", data["api"]), function(){
        cssLoaded(function(){
            let zoom = data["zoom"],
                coords = data["coords"].split(/[\s,]+/),
                marker = data["marker"];

            let map = new google.maps.Map(selector, {
                zoom: zoom,
                disableDefaultUI: true,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#181818"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#1b1b1b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#2c2c2c"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#8a8a8a"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#373737"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#3c3c3c"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#4e4e4e"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3d3d3d"
                            }
                        ]
                    }
                ],
                scrollwheel: false,
                center: {
                    lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                },
                mapTypeControl: false
            });

            let map_marker_position = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));

            let image = {
                path: 'M 67.742661,1.0984404e-6 A 67.730464,67.740094 0 0 0 0,67.733514 67.730464,67.740094 0 0 0 67.742661,135.46667 67.730464,67.740094 0 0 0 135.46667,67.733514 67.730464,67.740094 0 0 0 67.742661,1.0984404e-6 Z',
                fillColor: marker["color"],
                strokeOpacity: 0,
                strokeWeight: 0,
                fillOpacity: 1,
                scale: 0.1,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(32, 64)
            };

            let map_marker = new google.maps.Marker({
                position: map_marker_position,
                map: map,
                icon: image
            });

            google.maps.event.addListenerOnce(map, 'idle', function(){
                setTimeout(function(){
                    selector.querySelector("canvas").parentNode.classList.add("marker");
                },150);
            });

            // marker["width"] = 80;
            // marker["height"] = 80;
            //
            // let map_marker = new google.maps.Marker({
            //     position: map_marker_position,
            //     map: map,
            //     icon: {
            //         origin: new google.maps.Point(0, 0),
            //         anchor: new google.maps.Point(marker["width"]/2, marker["height"])
            //     },
            //     title: marker["title"]
            // });

            google.maps.event.addListener(map_marker, 'click', function(){
                window.open(marker["link"], '_blank');
            });
        });
    });
}