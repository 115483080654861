const LibDialog = {
    show: (content, name = "default", callback) => {
        if (document.querySelector(".lib--dialog > [class^='part_dialog']") !== null) {
            document.querySelector(".lib--dialog > [class^='part_dialog']").remove();
        }

        if (document.querySelector(".lib--dialog") === null) {
            document.body.insertAdjacentHTML("beforeend", `<div class="lib--dialog"></div>`);
        }

        document.querySelector(".lib--dialog").insertAdjacentHTML("beforeend", content);
        document.querySelector(".lib--dialog").style["display"] = "flex";

        if(name === "download") {
            document.querySelector(".lib--dialog").classList.add("is--download")
        } else {
            document.querySelector(".lib--dialog").classList.remove("is--download")
        }

        function outerHeight(el) {
            return el.offsetHeight + parseInt(getComputedStyle(el).marginTop) + parseInt(getComputedStyle(el).marginBottom);
        }

        if (outerHeight(document.querySelector(".lib--dialog > [class^='part_dialog']")) > window.innerHeight) {
            let offset = window.innerWidth - document.body.clientWidth;

            document.documentElement.style["padding-right"] = offset;
            document.documentElement.classList.add("overflow--hidden");

            if (document.querySelector("#layout_header") !== null) {
                document.querySelector("#layout_header").style["right"] = offset;
            }
        }

        if (callback) {
            callback();
        }

        document.querySelector(".lib--dialog").addEventListener("mousedown", (e) => {
            if (e.target.classList.contains("lib--dialog")) {
                document.documentElement.addEventListener("mouseup", function e() {
                    LibDialog.hide();
                    document.documentElement.removeEventListener("mouseup", e);
                });
            }
        }, true);
    },
    hide: (callback) => {
        if (document.querySelector(".lib--dialog") !== null) {
            document.querySelector(".lib--dialog").classList.add("state--hiding");
        }

        setTimeout(function () {
            if (document.querySelector(".lib--dialog") !== null) {
                document.querySelector(".lib--dialog").style["display"] = "none";
                document.documentElement.classList.remove("overflow--hidden");

                if (document.querySelector("#layout_header") !== null) {
                    document.querySelector("#layout_header").style["right"] = "";
                }

                document.querySelector(".lib--dialog").remove();
            }

            if (callback) {
                callback();
            }
        }, 300);
    },
    action: (element, url, callback) => {
        fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
            return response.json();
        })
            .then((data) => {
                LibDialog.show(data["dialog"], element.dataset.actionName, callback);
            });
    }
};
