application.register("comp_references_intro", class extends Stimulus.Controller {
    connect() {
        let selector = this.element;

        selector.addEventListener("mouseenter", (e) => {
            if (e.target.classList.contains("elm_item")) {

                [...selector.querySelectorAll(".elm_item")].forEach((element) => {
                    element.querySelector(".elm_item_text").classList.remove('state--hover')
                });
                e.target.querySelector(".elm_item_text").classList.add("state--hover");
            }
        }, true);
    }
});