application.register("lib-lottie", class extends Stimulus.Controller {
    connect() {
        importScript(cdnjs.lottie, ()=>{
            inView(this.element, ()=> {
                this.loadAnim()
            })
        })
    }

    loadAnim() {
        const player = this.element

        const anim = window.lottie.loadAnimation({
            container: player,
            renderer: 'svg',
            autoplay: false,
            loop: !!(typeof (this.element.dataset.libLottieLoop !== 'undefined')),
            path: this.element.dataset.src
        })

        anim.play()
    }
});
