application.register("lib-reveal", class extends Stimulus.Controller {
    get target() {
        return {
            items: this.targets.findAll("item")
        }
    }

    connect() {
        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const target = entry.target
                    target.classList.add('is-inview')

                    observer.unobserve(target)
                }
            })
        }, {
            rootMargin: '0px'
        })

        this.target.items.forEach(item => this.observer.observe(item))
    }

    disconnect() {
        this.target.items.forEach(item => this.observer.unobserve(item))
        this.observer.disconnect()
    }
});
