application.register("part_dialog_video", class extends Stimulus.Controller {
    connect() {
        let selector = this.element;

        let refs = {
            video: `[data-ref="video"]`
        };

        if (typeof lui.data["videoTime"] !== "undefined") {
            selector.querySelector(refs.video).querySelector("video").currentTime = lui.data["videoTime"];
        }

        selector.querySelector(refs.video).querySelector("video").play();

        selector.querySelector(`[data-click="close"]`).addEventListener("click", () => {
            selector.classList.add("is--hiding");
            selector.querySelector(refs.video).querySelector("video").pause();
            lui.data["videoTime"] = selector.querySelector(refs.video).querySelector("video").currentTime;
            LibDialog.hide();
        });

        selector.querySelector(`${refs.video} > video`).addEventListener("click", () => {
            if (selector.querySelector(refs.video).classList.contains("state--paused")) {
                selector.querySelector(refs.video).classList.remove("state--paused");
                selector.querySelector(refs.video).querySelector("video").play();
            } else {
                selector.querySelector(refs.video).classList.add("state--paused");
                selector.querySelector(refs.video).querySelector("video").pause();
            }
        });

        selector.querySelector(`${refs.video} > video`).addEventListener("ended", () => {
            selector.querySelector(refs.video).querySelector("video").pause();
        });
    }
});