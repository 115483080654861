const lui_dropdown = function(selector) {
    selector.addEventListener("click", (e) => {
        e.preventDefault();
        importScript(cdnjs.jquery, () => {
            selector = $(selector);
            let body = selector.find("[data-ref-body]");

            body.stop();

            if(selector.hasClass("state--active")) {
                body.slideUp();
                selector.removeClass("state--active");
            }
            else {
                body.slideDown();
                selector.addClass("state--active");
            }
        });
    })
};