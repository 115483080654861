application.register("comp_eshop_automation", class extends Stimulus.Controller {
    connect() {
        let element = this.element.querySelector(".elm_comp_animation");

        if (!document.documentElement.classList.contains("ie") && !element.classList.contains("is-inview")) {
            let callback = function (mutationsList, observer) {
                for (let mutation of mutationsList) {
                    if (element.classList.contains("is-inview")) {
                        observer.disconnect();
                        toggleAnimation(element);
                    }
                }
            };
            let observer = new MutationObserver(callback);
            observer.observe(element, {attributes: true, attributeFilter: ['class']});
        } else {
            toggleAnimation(element);
        }

        function toggleAnimation(element) {
            element.classList.add("state--animating");
            setInterval(() => {
                element.classList.remove("state--animating");
                element.offsetHeight;
                element.classList.add("state--animating");
            }, 13000);
        }
    }
});