application.register("part_form_contact", class extends Stimulus.Controller {
    connect() {
        let selector = this.element;

        let refs = {
            subjectToggleArea: `[data-ref="subjectToggleArea"]`
        };

        importScript(cdnjs.jquery, () => {

            $(selector).on("change", `[data-change="subjectToggleNoRedraw"]`, function () {
                let value = $(this).val();
                if(value) {
                    let form = $('.part_form_contact');
                    if (form.find(refs.subjectToggleArea).hasClass("state--disabled")) {
                        form.find(refs.subjectToggleArea).removeClass("state--disabled");
                    }
                }
            });

            $(selector).on("change", `[data-change="subjectToggle"]`, function () {
                let value = $(this).val();
                let ajaxLink = $(this).attr('data-ajax-link');

                $.ajax({
                    url: ajaxLink,
                    method: 'post',
                    data: {value: value},
                    dataType: "json",
                    cache: false,
                    success: function (data) {

                        fetchHandler(data, function () {

                            let selector = $('.part_form_contact');
                            if (selector.find(refs.subjectToggleArea).hasClass("state--disabled")) {
                                selector.find(refs.subjectToggleArea).removeClass("state--disabled");
                            }
                        });
                    }
                });

                window.plausible('FormSubject', {
                    props: {
                        formId: selector.id
                    }
                })
            });
        });
    }
});
