LibCookieConsent.init()

application.register("part_cookieconsent", class extends Stimulus.Controller {
    connect() {
        const selector = this.element;

        if (document.querySelector('.part_form_cookieconsent') !== null || selector === null) {
            return
        }

        if (LibCookieConsent.getCookie('lib-cookieconsent') === null) {
            setTimeout(() => {
                selector._addDataValue('state', 'active')
                selector.classList.add('is-animate')
                window.plausible('CookieConsent-Show')
            }, 3500)
        }

        selector.querySelector('[data-lib-cookieconsent-approve]').addEventListener('click', () => {
            this.hide(['performance', 'marketing'])
            window.plausible('CookieConsent-Approve')
        })

        selector.querySelector('[data-lib-cookieconsent-decline]').addEventListener('click', () => {
            this.hide([])
            window.plausible('CookieConsent-Decline')
        })
    }

    hide(type) {
        LibCookieConsent.set(type)
        this.element.classList.remove('is-animate')

        setTimeout(() => {
            this.element._removeDataValue('state', 'active')
            this.element.remove()
        }, 500)
    }
});

application.register("part_form_cookieconsent", class extends Stimulus.Controller {
    connect() {
        const selector = this.element
        const type = LibCookieConsent.getCookie('lib-cookieconsent')

        if (selector === null) {
            return
        }

        if (document.querySelector('.part_cookieconsent')) {
            document.querySelector('.part_cookieconsent').classList.remove('is-animate')
            document.querySelector('.part_cookieconsent')._removeDataValue('state', 'active')
        }

        if (type !== null) {
            selector.querySelectorAll('input:not([disabled])').forEach(input => input.checked = false)

            JSON.parse(type).forEach(type => {
                if (selector.querySelector(`input[value="${type}"]`) !== null) {
                    selector.querySelector(`input[value="${type}"]`).checked = true
                }
            })
        }

        selector.addEventListener('submit', e => {
            e.preventDefault()

            let type = []

            selector.querySelectorAll('input:not([disabled])').forEach(input => {
                input.checked && type.push(input.value)
            })

            LibCookieConsent.set(type)
            location.reload()
        })
    }
    disconnect() {
        const modal = document.querySelector('.part_cookieconsent')

        if (modal && LibCookieConsent.getCookie('lib-cookieconsent') === null) {
            modal._addDataValue('state', 'active')
            modal.classList.add('is-animate')
        }
    }
});
