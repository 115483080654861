application.register("part_dialog_download_brand", class extends Stimulus.Controller {
    handlePassword(e) {
        e.preventDefault()

        const frm = e.currentTarget,
            formData = new FormData(frm),
            input = e.currentTarget.querySelector('.part_ui_input'),
            warning = e.currentTarget.querySelector('.wrp_form_warning'),
            formFile = this.targets.find("form");

        warning.classList.add('display--none')

        importScript(cdnjs.jquery, () => {
            $.ajax({
                method: frm.getAttribute('method'),
                url: frm.getAttribute('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                const response = JSON.parse(payload)
                if(response.status === "errorPassword") {
                    warning.innerHTML = response.message
                    warning.classList.remove('display--none')
                    input.classList.remove('state--valid')
                    input.classList.add('state--invalid')
                } else if(response.status === 'success') {
                    formFile.insertAdjacentHTML('beforeend',`<input type="hidden" name="password" value="${response.message}" />`)
                    formFile.submit()
                    LibDialog.hide()
                }
            });
        })
    }
});
