application.register("lib-drawer", class extends Stimulus.Controller {

    get ref() {
        return {
            nav: this.targets.find("nav")
        }
    }

    connect() {
        setTimeout(() => this.ref.nav.classList.add("is--transition"), 50);
        this.ref.nav.addEventListener("click", (e) => {
            if (e.target === this.ref.nav) {
                this.hide(e);
            }
        })
    }

    toggle(e) {
        if (!e.currentTarget.classList.contains("state--active")) {
            this.show(e);
        } else {
            this.hide(e);
        }
    }

    show(e) {
        this.ref.nav.scrollLeft = 0;
        this.ref.nav.style.setProperty('--drawerOpacity', `1`);
        this.ref.nav.classList.add("is--opacity");
        this.ref.nav.classList.add("state--active", "state--active-visibility");
        e.currentTarget.classList.add("state--active", "state--active-visibility");
        document.documentElement.classList.add("drawer-active", "drawer-active-visibility");
        document.documentElement.style["overflow"] = "hidden";
    }

    hide(e) {
        this.ref.nav.classList.remove("state--active");
        document.querySelector(`[data-action="click->lib-drawer#toggle"]`).classList.remove("state--active");
        this.ref.nav.classList.add("is--opacity");
        this.ref.nav.style.setProperty('--drawerOpacity', `0`);
        document.documentElement.classList.remove("drawer-active");
        document.documentElement.style["overflow"] = "";
        document.querySelector(`[data-action="click->lib-drawer#toggle"]`).classList.remove("state--active", "state--active-visibility");

        setTimeout(() => {
            this.ref.nav.classList.remove("state--active-visibility");
            document.documentElement.classList.remove("drawer-active-visibility");
        },document.documentElement.clientWidth > 960 ? 1250 : 300);
    }

    scroll(e) {
        let opacity = Math.abs((e.target.scrollLeft / this.ref.nav.children[0].clientWidth) - 1);

        if (e.target.scrollLeft > 1) {
            this.ref.nav.classList.remove("is--opacity");
            document.querySelector(`[data-action="click->lib-drawer#toggle"]`).classList.remove("state--active", "state--active-visibility");
            this.ref.nav.style.setProperty('--drawerOpacity', `${opacity}`);
        }

        if (e.target.scrollLeft === this.ref.nav.children[0].clientWidth) {
            this.ref.nav.classList.remove("state--active", "state--active-visibility");
            document.documentElement.style["overflow"] = null;
        }
    }
});