function canvasVideo(v) {
  let hideOrig = false;

  let drawFrame = function(v,c) {
    if (v.paused || v.ended) return false;
    let w = v.videoWidth;
    let h = v.videoHeight;
    c.canvas.width = w;
    c.canvas.height = h;
    c.drawImage(v,0,0,w,h);
    v.timeout = setTimeout(drawFrame, 30, v,c);
  }

  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  v.parentNode.appendChild(canvas);
  if (hideOrig) v.classList.add('shy');

  drawFrame(v,ctx);

  v.addEventListener('playing', function() {
    if (typeof v.timeout === 'number') clearTimeout(v.timeout);
    drawFrame(this,ctx);
  });
}
