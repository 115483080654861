application.register("comp_cards_stack", class extends Stimulus.Controller {
    connect() {
        const self = this;

        this._cards = self.targets.findAll('card')

        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            '(min-width: 60em)': () => {
                const timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: self.targets.find('scene'),
                        start: 'top top',
                        end: `+${100 * self._cards.length}%`,
                        scrub: true,
                        pin: true
                    }
                })

                self._cards.forEach((card, index) => {
                    ScrollTrigger.saveStyles(card)
                    let start = '100vh'

                    if(index === 0) {
                        start = '-50%'
                    }

                    timeline.fromTo(card, {
                        translateY: start
                    }, {
                        translateY: `${-50 - ((self._cards.length - index - 1) * 5)}%`,
                    }, '>-175%')

                    timeline.to(card, {
                        scale: 1 - ((self._cards.length - index - 1) * 0.05)
                    }, '<+=20%')

                    if(index !== self._cards.length - 1) {
                        timeline.to(card, {
                            opacity: 0.5
                        }, '>+50%')
                    }
                })
            }
        })
    }
})
