application.register("comp_visual_shop", class extends Stimulus.Controller {
    connect() {
        let images = this.element.querySelectorAll("[data-comp-image]");
        if (images[0] !== null && images.length > 1) {
            let i = 0;
            setInterval(() => {
                images[i].classList.remove("state--active","state--init");
                if (i >= images.length - 1) {
                    i = 0;
                } else {
                    i++;
                }
                images[i].classList.add("state--active");
            }, parseInt(this.element.dataset.interval));
        }
    }
});