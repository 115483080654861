let lui_mousepos = { x: 0, y: 0 }

function luiGetMousePos(e) {
    return {
        x: e.clientX,
        y: e.clientY
    }
}

window.addEventListener('mousemove', e => lui_mousepos = luiGetMousePos(e))
